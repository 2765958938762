import { useState, useEffect, useRef } from 'react';
import { GearMarker } from './GearMarker';
import { BucketMarker } from './BucketMarker';
import { ProfileGear, GearMapBucket } from 'types';

import styles from './GearsMap.module.scss';

export const MIN_ZOOM = 2;
export const MAX_ZOOM = 18;

export type MapProps = {
  gears: ProfileGear[];
  buckets: GearMapBucket[];
  onChanged?: (mapData: MapData) => void;
  onBucketSelected?: (geoHash: string|null) => void;
};

export type MapData = {
  zoom: number;
  bounds: {
    tr: { // Top right
      lat: number;
      lng: number;
    };
    bl: { // Bottom left
      lat: number;
      lng: number;
    };
  };
};

export const Map = ({ gears, buckets, onChanged, onBucketSelected }: MapProps) => {
  const [selectedGear, setSelectedGear] = useState<string|null>(null);
  const [selectedBucket, setSelectedBucket] = useState<string|null>(null);

  const map = useRef<any>(null);
  const mapRef = useRef(null);
  const changesCountRef = useRef(0);

  useEffect(() => {
    if (mapRef.current && !map.current) {
      // @ts-ignore
      map.current = new window.google.maps.Map(mapRef.current, {
        center: { lat: 40.33189102875467, lng: -94.02030695705407 },
        zoom: 4,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      });

      map.current.addListener('bounds_changed', () => {
        handleChanged();
      });
      map.current.addListener('zoom_changed', () => {
        handleChanged();
      });
    }
  }, [mapRef, map]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onBucketSelected && onBucketSelected(selectedBucket);
  }, [selectedBucket]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChanged = () => {
    changesCountRef.current += 1;
    setTimeout(() => {
      changesCountRef.current -= 1;

      if (changesCountRef.current <= 0 && map.current && onChanged) {
        const bounds = map.current.getBounds();
        const tr = bounds.getNorthEast();
        const bl = bounds.getSouthWest();

        onChanged({
          zoom: map.current.getZoom(),
          bounds: {
            tr: {
              lat: tr.lat(),
              lng: tr.lng()
            },
            bl: {
              lat: bl.lat(),
              lng: bl.lng()
            },
          },
        });
      }
    }, 300);
  };

  const handleSelectGear = (id: string) => {
    setSelectedGear(prev => prev === id ? null : id);
    setSelectedBucket(null);
  };

  const handleSelectBucket = (geoHash: string) => {
    setSelectedBucket(prev => prev === geoHash ? null : geoHash);
    setSelectedGear(null);
  }

  return (
    <div ref={mapRef} id="searchMap" className={styles.mapContainer}>
      {gears.map(gear => <GearMarker
        key={gear.id}
        gear={gear}
        map={map.current}
        selected={selectedGear === gear.id}
        onSelect={() => handleSelectGear(gear.id)}
      />)}
      {buckets.map(bucket => <BucketMarker
        key={bucket.geo_hash}
        bucket={bucket}
        map={map.current}
        onSelect={() => handleSelectBucket(bucket.geo_hash)}
      />)}
    </div>
  );
};
