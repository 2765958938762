import { useState, useEffect, UIEvent } from 'react';
import { NextLink } from 'components/NextLink';
import { NextBackgroundImage, NextIcon } from 'components/NextImage';
import { QueryParams, ProfileGear } from 'types';
import { getGearList } from 'api';

import styles from './GearsMap.module.scss';

export type GeoHashListProps = {
  geoHash: string;
  filters: QueryParams;
};

const LIST_LIMIT = 20;

export const GeoHashList = ({ geoHash, filters }: GeoHashListProps) => {
  const [currentFilters, setCurrentFilters] = useState<QueryParams>(filters);
  const [gears, setGears] = useState<ProfileGear[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalGears, setTotalGears] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentFilters(filters);
    fetchGears(true, filters).then();
  }, [geoHash]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchGears = async (force = false, filters: QueryParams | null = null) => {
    // check all gears loaded
    if (!force && totalGears && currentPage >= Math.ceil(totalGears / LIST_LIMIT)) {
      return;
    }

    if (!force && isLoading) {
      return;
    }

    setIsLoading(true);
    const page = force ? 1 : currentPage + 1;
    const requestFilters = filters || currentFilters;
    const response = await getGearList({
      ...requestFilters,
      geo_hashes: [geoHash],
      page,
      limit: LIST_LIMIT,
    });

    if (response.ok && response.hits) {
      setCurrentPage(page);
      setTotalGears(response.total);
      setGears((previous) => {
        if (force) {
          return response.hits;
        }
        return [...previous, ...response.hits];
      });
    }
    setIsLoading(false);
  };

  const handleScroll = (e: UIEvent<HTMLElement>) => {
    const {
      currentTarget: { scrollHeight, scrollWidth, scrollTop, scrollLeft, offsetHeight, offsetWidth },
    } = e;
    const elBottom = window.getComputedStyle(e.currentTarget).bottom;

    if (elBottom === '0px' && scrollHeight - (Math.abs(scrollTop) + offsetHeight) <= 200) {
      fetchGears().then();
    } else if (elBottom !== '0px' && scrollWidth - (Math.abs(scrollLeft) + offsetWidth) <= 200) {
      fetchGears().then();
    }
  };

  return (
    <div className={styles.geoHashList} onScroll={handleScroll}>
      {gears.map((gear) => {
        const size = gear.sizes[0]?.title;
        const brand = gear.brand?.title;
        return (
          <NextLink key={gear.id} href={`/gear/${gear.slug}`}>
            <div className={styles.geoHashListItem}>
              <NextBackgroundImage src={gear.cover_image} customClass={styles.itemImage} />
              <div className={styles.itemContent}>
                <div className={styles.itemTitle}>{gear.title}</div>
                <div className={styles.itemPriceConteiner}>
                  <div className={styles.itemPriceWrapper}>
                    <div className={styles.itemPrice}>${gear.price}</div>
                    {(!!size || !!brand) && (
                      <div className={styles.itemSize}>
                        {!!size && `Size: ${size}`}
                        {!!size && !!brand && ` | `}
                        {!!brand && `Brand: ${brand}`}
                      </div>
                    )}
                  </div>
                  <NextIcon src="/icons/maps/MapPinFill.svg" />
                </div>
              </div>
            </div>
          </NextLink>
        );
      })}
    </div>
  );
};
