import { useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { ProfileGear, GearMapBucket, QueryParams } from 'types';
import { GOOGLE_MAPS_API_KEY } from 'consts';
import { Map, MapData, MIN_ZOOM, MAX_ZOOM } from './Map';
import { GeoHashList } from './GeoHashList';

import styles from './GearsMap.module.scss';

const MAX_GEOHASH_PRECISION = 12;

const zoomLevelToGeohashPrecision = (zoom: number) => {
  const zoomLevelsCount = MAX_ZOOM - MIN_ZOOM;
  const currentZoomBaseZero = zoom - MIN_ZOOM;

  if (currentZoomBaseZero === zoomLevelsCount) {
    return MAX_GEOHASH_PRECISION;
  } else if (currentZoomBaseZero <= 0) {
    return 1;
  }

  return Math.round((MAX_GEOHASH_PRECISION / zoomLevelsCount) * currentZoomBaseZero);
};

export type MapFilters = {
  precision: number;
  bounds: {
    tr: {
      // Top right
      lat: number;
      lng: number;
    };
    bl: {
      // Bottom left
      lat: number;
      lng: number;
    };
  };
};

export type GearsMapProps = {
  gears: ProfileGear[];
  buckets: GearMapBucket[];
  filters: QueryParams;
  onChange: (mapFilters: MapFilters) => void;
};

export const GearsMap = ({ gears, buckets, filters, onChange }: GearsMapProps) => {
  const [geoHash, setGeoHash] = useState<string | null>(null);

  const handleChange = (mapData: MapData) => {
    onChange({
      precision: zoomLevelToGeohashPrecision(mapData.zoom),
      bounds: mapData.bounds,
    });
  };

  const handleBucketSelected = (geoHash: string | null) => {
    setGeoHash(geoHash);
  };

  return (
    <div className={styles.container} suppressHydrationWarning={true}>
      <Wrapper apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || GOOGLE_MAPS_API_KEY}>
        <Map gears={gears} buckets={buckets} onChanged={handleChange} onBucketSelected={handleBucketSelected} />
      </Wrapper>
      {geoHash && <GeoHashList geoHash={geoHash} filters={filters} />}
    </div>
  );
};
